import { Pipe, PipeTransform } from '@angular/core';
import { selectorTypes } from '../constants/selectorTypes';

@Pipe({
  name: 'getQuestionPoints'
})
export class GetQuestionPointsPipe implements PipeTransform {

  transform(question, answer) {
    if (question.type === selectorTypes.text) {
      return answer ? question.weights[0] : 0;
    } else if (question.type === selectorTypes.checkbox) {
      if (question.answer_type === 'general') {
        let isCorrect = true;
        question.general_checked.forEach((checked, wI) => {
          if (answer[wI] !== checked) {
            isCorrect = false;
          }
        });
        return isCorrect ? question.weight : 0;
      } else if (question.answer_type === 'each') {
        let result = 0;
        question.weights.forEach((score, wI) => {
          if (answer[wI] && score) {
            result += score;
          }
        });
        return result;
      }
    } else if (question.type === selectorTypes.radiobox) {
      if (question.answer_type === 'general') {
        return question.general_radio === answer ? question.weight : 0;
      } else if (question.answer_type === 'each') {
        return question.weights[answer] || 0;
      }
    }
    return 0;
  }

}
