import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss',],
  encapsulation: ViewEncapsulation.None
})
export class TimerComponent implements OnInit {
  @Input() set duration(val) {
    if (!val) { return; }
    this.timeLeft = val;

    let mm = Math.floor(val / 60);
    this.m1 = Math.floor(mm / 10);
    this.m2 = mm % 10;
    
    let ss = val % 60;
    this.s1 = Math.floor(ss / 10);
    this.s2 = ss % 10;
  };
  @Input() set start(val: boolean) {
    if (!val) { return; }
    this.run = true;
    this.runInterval = setInterval(() => {
      if (!this.run) { return; }
      this.timeLeft--;
      let mm = Math.floor(this.timeLeft / 60);
      this.m1 = Math.floor(mm / 10);
      this.m2 = mm % 10;
    
      let ss = this.timeLeft % 60;
      this.s1 = Math.floor(ss / 10);
      this.s2 = ss % 10;
      
      if (this.timeLeft <= 0) {
        this.end.emit();
        this.run = false;
        clearInterval(this.runInterval);
      }

      this.tick.emit(this.timeLeft);
    }, 1000);
  };
  @Input() set stop(val: boolean) {
    if (!val) { return; }
    this.run = false;
    clearInterval(this.runInterval);
    this.tick.emit(this.timeLeft);
    // this.end.emit(this.timeLeft);
  };
  @Output() tick: EventEmitter<number> = new EventEmitter<number>();
  @Output() end: EventEmitter<null | number> = new EventEmitter<null | number>();

  private timeLeft: number = 0;

  private run: boolean;
  private runInterval: any;

  public m1: number = 0;
  public m2: number = 0;
  public s1: number = 0;
  public s2: number = 0;
  
  constructor() { }

  ngOnInit(): void {}

}
