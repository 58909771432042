import { NgModule } from '@angular/core';
import {SanitizerPipe} from './sanitize.pipe';

@NgModule({
  declarations: [SanitizerPipe],
  exports: [
    SanitizerPipe
  ]
})
export class SanitizePipeModule { }
