import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { RouterModule } from '@angular/router';

import { QuizPopupComponent } from './quiz-popup.component';
// import { QuizNoAnswersComponent } from './quiz-no-answers/quiz-no-answers.component';
import { QuizResultComponent } from './quiz-result/quiz-result.component';

import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TimerModule } from '../timer/timer.module';
import { GetQuestionPointsPipe } from './pipes/getQuestionPoints.pipe';
import { QuizRequestComponent } from './quiz-request/quiz-request.component';
import {SanitizePipeModule} from '../../pipes/sanitize/sanitize.module';

@NgModule({
  declarations: [
    QuizPopupComponent,
    QuizResultComponent,
    GetQuestionPointsPipe,
    QuizRequestComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // RouterModule,

    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,

    TimerModule,
    SanitizePipeModule,
  ],
  entryComponents: [
    QuizPopupComponent,
    QuizResultComponent,
    QuizRequestComponent
  ]
})
export class QuizPopupModule {
}
